@font-face {
  font-family: 'NeoDunggeunmoPro-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/NeoDunggeunmoPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

a {
  text-decoration: none;
  color: inherit;
}

input:focus{
  border: 1px solid lightgray;
  filter: drop-shadow(0 0 0.2rem rgb(0, 0, 0, 0.1));
}

.fameValue {
  border-radius: 50px;
  margin: 20px;
  padding: 15px;
  outline: none;
  border: 1px solid lightgray;
  box-sizing: border-box;
  font-family: 'pretendard';
  font-size: 17px;
  font-weight: 900;
}

.App {
  height: 100vh;
  overflow-x: hidden;
}

.App::before {
  position:absolute;
  content: "";
  top: -30%;
  left: -50%;
  width: 200%;
  min-height: 150%;
  z-index: -5;
  transform: rotate(-10deg);
  background-blend-mode: screen;
  background-color: whitesmoke;
  background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.8) 20%,
      rgba(255, 255, 255, 1) 80%
    ),
    url("https://yt3.googleusercontent.com/ke-SMZRlglnJOdzDo8x5MjlW3HkNot6XIVXM56xHoOLKpQ4o3EGY83CGm8GCHViu_XR2mmRq=s176-c-k-c0x00ffffff-no-rj");
}

.header {
  display: flex;
  width: 100%;
  height: 100px;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
}

.header > a {
  display: flex;
  width: max-content;
  align-items: end;
  justify-content: center;
}

.header > a > div > img {
  width: 250px;
  height: fit-content;
}

.header > a > div > svg {
  height: min-content;
  bottom: 0;
  font-size: 20px;
}

.burger {
  position: absolute;
  right: 2em;
  top: 2em;
  font-size: 35px;
  cursor: pointer;
}

.patty {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
}

.sauce {
  font-family: 'pretendard';
  letter-spacing: -1px;
  font-weight: 200;
  font-size: 50px;
  padding: 5em 2em;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: end;
  float: right;
  color: white;
  gap: 5px;
  text-align: right;
}

.sauce > a {
  width: fit-content;
  border-bottom: 1px solid rgb(0,0,0,0);
  transition: 0.2s all ease;
  padding-bottom: 5px;
}

.sauce > a:hover {
  border-bottom: 1px solid white;
  transition: 0.2s all ease;
}

.closeBtn {
  font-size: 35px;
  position: absolute;
  top: 2em;
  right: 2em;
  cursor: pointer;
}

/* */

.contents {
  margin: 5em 0;
}

.page {
  margin: 0 15em;
  font-family: 'pretendard';
}

.youtube {
  display: flex;
  width: 100%;
  gap: 15px;
}

.youtube > div {
  width: 50%;
}

.youtube > div > span {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 23px;
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.youtube > div > div {
  width: 100%;
  height: 390px;
  border-radius: 20px;
  overflow: hidden;
  display: block;
  margin: 0 auto;
}

/* */

.mcstatus {
  width: 100%;
  height: 450px;
  border-radius: 20px;
  background-image: url('img/Dirt_background_JE2.webp');
  font-family: 'NeoDunggeunmoPro-Regular';
  color: white;
  overflow: hidden;
}

.mctitle {
  background-image: url('img/Grass_minecraft.png');
  background-size: 10%;
  width: 100%;
  padding: 20px;
  font-size: 25px;
  font-weight: 400;
}

.mcinfo {
  padding: 30px;
  font-size: 17px;
  white-space: pre-wrap;
}

.mcarticle {
  display: flex;
  gap: 5px;
}

.mcdevide {
  height: 0.5px;
  border: 0;
  background-color: rgb(227, 227, 227, 0.7);
}

.mcbutton {
  background-image: url('img/stone.png');
  background-color: black;
  width: 200px;
  padding: 8px 12px;
  border-radius: 2px;
  overflow: hidden;
  margin: 10px 0;
  cursor: pointer;
  border: 2px solid black;
  filter: brightness(105%);
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.mcbutton:hover {
  background-color: rgb(154, 154, 225);
  background-blend-mode: overlay;
}

.mcbutton > p {
  padding: 0;
  margin: 0;
  filter: drop-shadow(2px 2px 1px black);
  height: min-content;
  text-align: center;
}

.mcevent {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 23px;
  letter-spacing: -1px;
}

/* */

.dcWidget {
  display: flex;
  width: 100%;
  gap: 15px;
}

.dcWidget > div {
  width: 50%;
  border-radius: 20px;
  overflow: hidden;
  display: block;
  bottom: 0;
  margin: 0 auto;
}

.dcBot {
  padding: 20px 0;
  border: 1px solid lightgray;
  background: linear-gradient(120deg, rgb(144, 204, 228, 0.5), rgb(187, 201, 207, 0.35));
}

/* */

.fameTitle {
  display: flex;
  justify-content: space-between;
  font-size: 23px;
  font-weight: 700;
  align-items: center;
}

.iconTitle {
  display: flex;
  align-items: center;
  gap: 5px;
}

.iconTitle > svg {
  font-size: 30px;
}

.btn {
  align-items: center;
  display: flex;
  border-radius: 8px;
  background-color: royalblue;
  border: 1px solid rgb(0,0,0,0);
  padding: 8px;
  color: white;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn:hover {
  background-color: rgb(245,245,245);
  border:1px solid royalblue;
  color: royalblue;
  transition: all 0.2s ease;
}

.fameAssets {
  border-radius: 20px;
  background-color: rgb(243,243,243);
  border: 1px solid lightgray;
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.fameList {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.fameAssets > div {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  height: auto;
}

.uploadform {
  position: absolute;
  display: none;
  width: 50%;
  height: 50%;
  top: 0;
  left: 0;
  background-color: white;
  color: black;
  transform: translateX(50%) translateY(50%);
  border-radius: 20px;
  border: 1px solid rgb(245,245,245);
  filter: drop-shadow(0 0 1.5rem rgb(0,0,0, 0.15));
}

.uploadform > div {
  padding: 20px;
}

.fameImg {
  padding: 1em;
  width: 300px;
  height: max-content;
  overflow: hidden;
  border: 1px solid lightgray;
  border-radius: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  background-color: rgb(255,255,255, 0.3);
}

.fameImg > div {
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.fameImg > div > picture > img {
  width: 100%;
  display: block;
}

/* */
.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  letter-spacing: -.5px;
  font-family: 'pretendard';
  height: 100px;
  gap: 10px;
}

.footer > div {
  margin: 0 auto;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 820px) {
  .App {
    width: 100%;
    height: auto;
    overflow-x: hidden;
  }

  .App::before {
    overflow-x: hidden;
    width: 100%;
    transform: none;
    top: 0;
    left: 0;
    height: 100%;
  }

  .header {
    width: auto;
    justify-content: space-evenly;
  }

  .header > a > div > img {
    width: 200px;
    height: fit-content;
  }

  .burger {
    position: static;
  }

  .sauce {
    padding: 5em 1em;
  }

  .closeBtn {
    right: 1em;
  }

  .page {
    margin: 0 1.5em;
  }

  .mctitle {
    font-size: 20px;
  }

  .fameAssets > div {
    width: auto;
  }

  .fameList {
    width: auto;
  }

  .fameImg {
    width: 100%;
  }

  .footer {
    width: auto;
    margin: 0 1.5em;
  }

  .footer > div {
    font-size: 10px;
  }
}